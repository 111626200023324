/* eslint-disable react/jsx-props-no-spreading */
import { animated } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Marker } from 'react-map-gl';

import MapMarker from '../icons/MapMarker';

import styles from '../../styles/maps/icon-marker.module.css';

const propTypes = {
    theme: PropTypes.string,
    active: PropTypes.bool,
    highlighted: PropTypes.bool,
    faded: PropTypes.bool,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    icons: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    children: PropTypes.number,
    onClick: PropTypes.func,
    cursor: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.objectOf(PropTypes.string),
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    pinClassName: PropTypes.string,
};

const defaultProps = {
    theme: null,
    active: false,
    highlighted: false,
    faded: false,
    icons: [],
    children: null,
    onClick: null,
    cursor: null,
    size: null,
    style: null,
    className: null,
    iconClassName: null,
    pinClassName: null,
};

function IconMarker({
    theme,
    active,
    highlighted,
    faded,
    icons,
    children,
    latitude,
    longitude,
    onClick,
    cursor,
    size,
    style,
    className,
    iconClassName,
    pinClassName,
    ...props
}) {
    return (
        <Marker
            latitude={latitude}
            longitude={longitude}
            anchor="bottom"
            onClick={onClick}
            style={{ cursor }}
            {...props}
        >
            <animated.div
                className={classNames([
                    styles.container,
                    {
                        [styles[theme]]: typeof styles[theme] !== 'undefined',
                        [styles.active]: active,
                        [styles.highlighted]: highlighted,
                        [styles.faded]: faded,
                        [className]: className !== null,
                    },
                ])}
                style={{
                    width: size,
                    ...style,
                }}
            >
                <MapMarker
                    theme={theme}
                    icons={icons}
                    className={classNames([
                        styles.icon,
                        {
                            [iconClassName]: iconClassName !== null,
                        },
                    ])}
                    pinClassName={classNames([styles.pin, pinClassName])}
                >
                    {children}
                </MapMarker>
            </animated.div>
        </Marker>
    );
}

IconMarker.propTypes = propTypes;
IconMarker.defaultProps = defaultProps;

export default IconMarker;
