/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';

import BasicButton from './BasicButton';

import styles from '../../styles/buttons/map-control-button.module.css';

const MapIcon = React.lazy(() => import('../icons/maps/MapIcon'));

const propTypes = {
    icon: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.string,
    className: PropTypes.string,
    iconClassName: PropTypes.string,
};

const defaultProps = {
    icon: null,
    onClick: null,
    children: null,
    className: null,
    iconClassName: null,
};

function MapControlButton({ icon, onClick, children, className, iconClassName, ...props }) {
    return (
        <BasicButton
            onClick={onClick}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            {...props}
        >
            <Suspense
                fallback={
                    <span
                        className={classNames([
                            styles.icon,
                            {
                                [iconClassName]: iconClassName !== null,
                            },
                        ])}
                    />
                }
            >
                <MapIcon
                    icon={icon}
                    className={classNames([
                        styles.icon,
                        {
                            [iconClassName]: iconClassName !== null,
                        },
                    ])}
                />
            </Suspense>
        </BasicButton>
    );
}

MapControlButton.propTypes = propTypes;
MapControlButton.defaultProps = defaultProps;

export default MapControlButton;
