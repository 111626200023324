import { useTransition } from '@react-spring/web';
import classNames from 'classnames';
import mapboxgl from 'mapbox-gl';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useMap } from 'react-map-gl';
import useSupercluster from 'use-supercluster';

import ClusterMarker from './ClusterMarker';
import IconMarker from './IconMarker';

import styles from '../../styles/maps/clusters.module.css';

const propTypes = {
    points: PropTypes.arrayOf(PropTypes.shape({})),
    bounds: PropTypes.arrayOf(PropTypes.number).isRequired,
    zoom: PropTypes.number.isRequired,
    radius: PropTypes.number,
    maxZoom: PropTypes.number,
    theme: PropTypes.string,
    markerIcons: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    withoutClusterMarker: PropTypes.bool,
    onClickPoint: PropTypes.func,
    markerClassName: PropTypes.string,
    clusterClassName: PropTypes.string,
};

const defaultProps = {
    points: [],
    radius: 40,
    maxZoom: 11,
    theme: null,
    markerIcons: null,
    withoutClusterMarker: false,
    onClickPoint: null,
    markerClassName: null,
    clusterClassName: null,
};

function Clusters({
    points,
    bounds,
    zoom,
    radius,
    maxZoom,
    theme,
    markerIcons,
    withoutClusterMarker,
    onClickPoint,
    markerClassName,
    clusterClassName,
}) {
    const boundsObject = useMemo(
        () => new mapboxgl.LngLatBounds([bounds[0], bounds[1]], [bounds[2], bounds[3]]),
        [bounds],
    );

    const { current: map } = useMap();

    const clusterOptions = useMemo(
        () => ({
            radius,
            maxZoom,
            reduce: (acc, props) => {
                acc.active = acc.active || props.active;
                return acc;
              }
        }),
        [radius, maxZoom],
    );

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom,
        options: clusterOptions,
    });

    const finalClusters = useMemo(
        () => clusters.filter(({ geometry }) => boundsObject.contains(geometry.coordinates)),
        [clusters],
    );

    const onClickCluster = useCallback(
        ({
            id: clusterId,
            geometry: {
                coordinates: [longitude, latitude],
            },
        }) => {
            map.flyTo({
                center: [longitude, latitude],
                zoom: supercluster.getClusterExpansionZoom(clusterId),
            });
        },
        [supercluster, maxZoom, map],
    );

    const transitions = useTransition(finalClusters || [], {
        keys: ({ type, id }) => `${type}-${id}`,
        initial: { scale: 1 },
        enter: { scale: 1 },
        leave: { scale: 0 },
    });

    return transitions((style, cluster) => {
        const {
            properties: {
                cluster: isCluster = false,
                point_count: pointCount,
                active,
                location: clusterLocation = null,
            },
            geometry: {
                coordinates: [longitude, latitude],
            },
        } = cluster || {};

        const { types = null } = clusterLocation || {};

        const pointIcons =
            types !== null
                ? (types || []).reduce(
                      (acc, { handle = null }) => (handle !== null ? [...acc, handle] : acc),
                      [],
                  )
                : null;

        return isCluster && !withoutClusterMarker ? (
            <ClusterMarker
                className={classNames([
                    styles.cluster,
                    {
                        [clusterClassName]: clusterClassName !== null,
                    },
                ])}
                active={active}
                longitude={longitude}
                latitude={latitude}
                anchor="center"
                cursor="pointer"
                theme={theme}
                size={30 + pointCount * 0.2}
                onClick={(e) => onClickCluster(cluster, e)}
                style={{ ...style }}
            >
                {pointCount}
            </ClusterMarker>
        ) : (
            <IconMarker
                className={classNames([
                    styles.marker,
                    {
                        [markerClassName]: markerClassName !== null,
                    },
                ])}
                icons={pointIcons || markerIcons}
                longitude={longitude}
                latitude={latitude}
                active={active}
                anchor="bottom"
                cursor="pointer"
                theme={theme}
                onClick={(e) =>
                    isCluster ? onClickCluster(cluster, e) : onClickPoint(clusterLocation, e)
                }
                style={{ ...style }}
            />
        );
    });
}

Clusters.propTypes = propTypes;
Clusters.defaultProps = defaultProps;

export default Clusters;
