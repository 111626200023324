/* eslint-disable react/jsx-props-no-spreading */
import { animated } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Marker } from 'react-map-gl';

import styles from '../../styles/maps/cluster-marker.module.css';

const propTypes = {
    theme: PropTypes.string,
    active: PropTypes.bool,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    children: PropTypes.number,
    onClick: PropTypes.func,
    cursor: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.objectOf(PropTypes.string),
    className: PropTypes.string,
    circleClassName: PropTypes.string,
    labelClassName: PropTypes.string,
};

const defaultProps = {
    theme: null,
    active: false,
    children: null,
    onClick: null,
    cursor: null,
    size: null,
    style: null,
    className: null,
    circleClassName: null,
    labelClassName: null,
};

function ClusterMarker({
    theme,
    active,
    children,
    latitude,
    longitude,
    onClick,
    cursor,
    size,
    style,
    className,
    circleClassName,
    labelClassName,
    ...props
}) {
    return (
        <Marker
            latitude={latitude}
            longitude={longitude}
            anchor="bottom"
            onClick={onClick}
            style={{ cursor }}
            {...props}
        >
            <animated.div
                className={classNames([
                    styles.container,
                    {
                        [styles.active]: active,
                        [styles[theme]]: typeof styles[theme] !== 'undefined',
                        [className]: className !== null,
                    },
                ])}
                style={{
                    width: size,
                    ...style,
                }}
            >
                <div
                    className={classNames([
                        styles.circle,
                        {
                            [circleClassName]: circleClassName !== null,
                        },
                    ])}
                >
                    <div
                        className={classNames([
                            styles.label,
                            {
                                [labelClassName]: labelClassName !== null,
                            },
                        ])}
                    >
                        {children}
                    </div>
                </div>
            </animated.div>
        </Marker>
    );
}

ClusterMarker.propTypes = propTypes;
ClusterMarker.defaultProps = defaultProps;

export default ClusterMarker;
