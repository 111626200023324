import { useResizeObserver, useWindowSize } from '@folklore/hooks';
import geoViewport from '@mapbox/geo-viewport';
import mapboxgl from 'mapbox-gl';
import { useCallback, useMemo, useState } from 'react';

function useMapViewport({ boundsPoints = [], maxZoom, initialViewport = null } = {}) {
    const {
        ref: mapSizeRef,
        entry: { contentRect = null },
    } = useResizeObserver();
    const { width: windowWidth, height: windowHeight } = useWindowSize();
    const { width: mapWidth = windowWidth, height: mapHeight = windowHeight } = contentRect || {};

    const {
        center: [initialLongitude, initialLatitude],
        zoom,
    } = useMemo(() => {
        const pointsBounds = boundsPoints.reduce(
            (
                bounds,
                {
                    geometry: {
                        coordinates: [longitude, latitude],
                    },
                },
            ) => {
                bounds.extend([longitude, latitude]);
                return bounds;
            },
            new mapboxgl.LngLatBounds(),
        );

        return geoViewport.viewport(
            [
                pointsBounds.getWest(),
                pointsBounds.getSouth(),
                pointsBounds.getEast(),
                pointsBounds.getNorth(),
            ],
            [mapWidth - 100, mapHeight - 100],
            undefined,
            maxZoom,
        );
    }, [boundsPoints !== null, mapWidth, mapHeight]);

    const [viewport, setViewport] = useState({
        latitude: initialLatitude,
        longitude: initialLongitude,
        zoom,
        ...initialViewport,
    });

    const bounds = useMemo(
        () =>
            geoViewport.bounds([viewport.longitude, viewport.latitude], viewport.zoom, [
                mapWidth,
                mapHeight,
            ]),
        [viewport.latitude, viewport.longitude, viewport.zoom, mapWidth, mapHeight],
    );

    const updateBounds = useCallback(() => {
        setViewport({
            ...viewport,
            latitude: initialLatitude,
            longitude: initialLongitude,
            zoom,
        });
    }, [viewport, initialLatitude, initialLongitude, zoom]);

    return {
        ref: mapSizeRef,
        width: mapWidth,
        height: mapHeight,
        bounds,
        viewport,
        setViewport,
        updateBounds,
    };
}

export default useMapViewport;
